import React from "react"
import styled from "styled-components";
import constants from "../../globals/constants";

const StyledElement = styled.div`
  max-width: 30rem;
  padding-top: 4rem;
  height: ${props => props.divHeight};
  
  .content {
    border: 4px #fff solid;
    margin: 0 0 0 6rem;
    padding: 2rem;
    @media ${constants.device.mobileXL} {
      margin: 1rem;
      padding: 1rem;
    }
    h2 {
      margin: 0 0 4rem 0;
      color: white;

    }

    a {
      position: relative;
      transition: .2s ease-in-out;
      margin: 0 auto 0 0;
      padding: 0.2rem;
      font-size: 1.5rem;

      &:hover, &:focus{
        color: ${props => props.fontColor};
        &:before {
          height:100%;
        }
      }
      &:before {
        z-index: -1;
        content: '';
        height:2px;
        width: 100%;
        background-color: white;
        position: absolute;
        left: 0;
        bottom: 0;
        transition: .2s ease-in-out;
      }
    }
  }

`;



const TransparentOverlayElement = ((props) => (

  <StyledElement id={props.anchor} fontColor={props.bgColor} divHeight={props.height}>
    <div  className="content">
      <h2>{props.title}</h2>
      <span>{props.text}</span>
      <a href={props.url}>{props.urlText}</a>
    </div>
  </StyledElement>
))

export default TransparentOverlayElement
