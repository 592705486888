import React, { Component } from 'react';
import styled from "styled-components";
import theme from "../globals/themes";
import {CSSTransition} from 'react-transition-group'
import client from "../client";
import BlockContent from '@sanity/block-content-to-react'
import constants from "../globals/constants";
import arrowBack from "../assets/img/arrow_back.svg";
import {Link} from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";



//🐥🐇

const ProjectSection = styled.section`
    height: 100%;
    background-color: ${theme.background.base}
    max-width: 1200px;
    margin: 0 auto;
    @media ${constants.device.containerWidth} {
        margin: 0 2rem;
    }
    padding-top: 2rem;
    padding-bottom: 5rem;
    .link{
        display:flex;
        margin-right: auto;
        h1 {
            margin: 0 0 0 .25rem;
        }
        img {
            height: 2.5rem;
            width: 2.5rem;
            margin: auto 0;
        }
    }
`;

const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2rem 150px 5rem 150px;
    @media ${constants.device.containerWidth} {
        margin: 2rem 10% 0 10%;
    }
    @media ${constants.device.mobileL} {
        margin: 2rem 0 0 0;
    }
    .image-container {

        img {
            width:100%;
            border-radius: 8px;
        }
    }

`;

const InnerContent = styled.div`
    display: flex;
    margin-top: 2rem;

    .inner {
        flex:1;
        > p {
            margin: 0 .5rem 0 0;
        }
    }
`;

const JobContent = styled.div`
    border-radius: ${theme.other.cardRadius}
    padding: 1rem;
    display: flex;
    margin-left: .5rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    border: 1px ${theme.secondary.standardIcon} solid;
    div {
        display: flex;
        flex-wrap: wrap;
    }
    h3 {
        margin: 0 0 1rem 0;
        white-space: nowrap;
    }
    p {
        margin: 0 0 0 1rem;
    }

`;

const JobAreaBadge = styled.div`
    border-radius: 18px;
    border: 1px rgba(0,0,0,0.05) solid;
    background-color: ${props => props.bgColor};
    font-size: 1.125rem;
    padding: .5rem 1rem;
    margin: 0 .5rem;
    max-height: 37px;
    :first-child {
        margin: 0 .5rem 0 0;
    }
    :nth-child(n+3) {
        margin: .5rem .5rem 0 .5rem;
    }
`;


const builder = imageUrlBuilder(client);

class ProjectPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            currentProject: [],
            jobAreas : [],
            categories : []
        }

    }

    componentDidMount(){
        this.fetchData();
    }

    fetchData(){
        const projectUrlName = this.props.match.params.projectUrlName;
        const slug = {slug : projectUrlName};

        this.setState({
            isLoading: true,
            currentProject: []
        });

        const query = '*[_type == "projectCase" && slug.current == $slug][0]';
        const jobAreaQuery = '*[_type == "jobArea" && _id == $id][0]';
        const categoryQuery = '*[_type == "category" && _id == $id][0]';

        const fetchProject = client.fetch(query, slug).then(data => {
            return data;
        });
        fetchProject.then(currentProject => {
            const jobAreasArray = [];
            let jobAreas = [];
            let category = [];

            currentProject.jobAreas.forEach(ref => {
                const id = {id : ref._ref};
                jobAreas = client.fetch(jobAreaQuery, id).then(jobArea => {
                    jobAreasArray.push(jobArea)
                }).then(d => {
                    return jobAreasArray;
                })
            });
            jobAreas.then(jobAreas => {
                currentProject.jobAreas = jobAreas;
                this.setState({
                    currentProject,
                })
            });

            currentProject.categories.forEach(ref => {
                const id = {id : ref._ref};
                category = client.fetch(categoryQuery, id).then(category => {
                    return category;
                })
            });
            category.then(category => {
                currentProject.categories = category;
                this.setState({
                    currentProject,
                    isLoading: false
                })
            })

        });

    }


    render() {
        const {projectId, dataset} = client.config();
        const {currentProject, isLoading} = this.state;
        console.log(currentProject);


        const mainImgUrl = currentProject.mainImage ? builder
          .image(currentProject.mainImage.asset)
          .width(900)
          .height(600)
          .url() : "";


        return (
          <>
              {this.state.isLoading === false ?
                <CSSTransition appear={true} in={!this.state.isLoading} timeout={0} classNames="slide-up">
                    <ProjectSection className="ProjectPage">
                        <Link to={"/"} className={"link"}>
                            <img src={arrowBack} alt={"back-link"}/>
                            <h1> {currentProject.title}</h1>
                        </Link>
                        <MainContent>

                            <div className={"image-container"}>
                                <img src={mainImgUrl} alt={"main image"}/>
                            </div>
                            <InnerContent>
                                <div className={"inner"}>
                                    <BlockContent blocks={currentProject.body} projectId={projectId} dataset={dataset} />
                                </div>
                                <div className={"inner"}>
                                    <JobContent className={"border-content"}>
                                        <h3>What I did</h3>
                                        <div>
                                            {this.state.currentProject.jobAreas ? this.state.currentProject.jobAreas.map((jobArea,i) =>
                                              <JobAreaBadge key={i} bgColor={jobArea.color} ><span>{jobArea.title}</span></JobAreaBadge>) : <p>Nothin to see here</p>}
                                        </div>
                                    </JobContent>
                                    <JobContent className={"border-content"}>
                                        <h3>Developed for</h3>
                                        <div>
                                            <img src={builder
                                              .image( currentProject.categories.categoryIcon.asset)
                                              .height(96)
                                              .width(96)
                                              .url()} alt={currentProject.categories.categoryIcon.title}/>

                                            <p>{currentProject.categories.description}</p>
                                        </div>
                                    </JobContent>
                                </div>
                            </InnerContent>
                        </MainContent>

                    </ProjectSection>
                </CSSTransition> : ""}
          </>
        );
    }
}

export default ProjectPage;
