import React, { Suspense, useState, useRef } from "react"
import { Canvas } from "@react-three/fiber"
import {Environment, Html, useProgress} from "@react-three/drei"
import Model from "./Model"
import Overlay from "./Overlay"
import styled from "styled-components";


const CanvasDiv = styled.section`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1;
`
const LoadingDiv = styled.section`
  height: 3rem;
  width: 50vw;
  border: 4px solid white;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    left: 0;
    background-color: white;
    height: 100%;
    width: ${props => props.progress}%; 
  }
`



export default function ThreeDCanvas() {
  const overlay = useRef();
  const caption = useRef();
  const scroll = useRef(0);
  const [currentIndex, setCurrentIndex] = useState();
  const [currentBg, setCurrentBg] = useState();

  const Loader = (() => {
    const { active, progress, errors, item, loaded, total } = useProgress();
    return <Html center zIndexRange={[1, 0]} >
      <LoadingDiv progress={progress}>
      </LoadingDiv>
    </Html>
  })

  const handleChange = ((newIndex) => {
    setCurrentIndex(newIndex)
  })
  const handleBgChange = ((newBg) => {
    setCurrentBg(newBg)
  })

  return (
    <CanvasDiv>
      <Canvas
        shadows
        onCreated={(state) => state.events.connect(overlay.current)}
        raycaster={{ computeOffsets: ({ clientX, clientY }) => ({ offsetX: clientX, offsetY: clientY }) }}>
        <ambientLight intensity={1} />
        <Suspense fallback={<Loader/>}>
          <Model scroll={scroll} currentIndex={currentIndex} currentBg={currentBg}/>
        </Suspense>
      </Canvas>
      <Overlay ref={overlay} caption={caption} scroll={scroll} handleIndexChange={handleChange} handleBgChange={handleBgChange}/>
    </CanvasDiv>
  )
}
