import React, { Suspense, useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import {useGLTF, Html, OrbitControls, useProgress} from '@react-three/drei'
import styled from "styled-components";
import * as THREE from "three";

const CanvasWrapper = styled.div`
  max-width: 100%;
  height: 32rem;
`
const LoadingDiv = styled.section`
  height: 3rem;
  width: 10vw;
  border: 4px solid white;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    left: 0;
    background-color: white;
    height: 100%;
    width: ${props => props.progress}%;
  }
`
const color = new THREE.Color();
export default function OrbitControlModel(props) {
  return (
    <CanvasWrapper>
      <Canvas camera={{ position: [10, 0, 0], fov: 30 }}>
        <directionalLight position={[10, 10, 0]} intensity={1.5} />
        <directionalLight position={[-10, 10, 5]} intensity={1} />
        <directionalLight position={[-10, 20, 0]} intensity={1.5} />
        <directionalLight position={[0, -10, 0]} intensity={0.25} />
        <Suspense fallback={<Loader/>}>
          <OrbitControls enableRotate={true} enableZoom={false} enablePan={false} />
          <Model currentBg={props.currentBg} pageType = {props.pageType} />
        </Suspense>
      </Canvas>
    </CanvasWrapper>
  )
}
const Loader = (() => {
  const { active, progress, errors, item, loaded, total } = useProgress();
  return <Html center zIndexRange={[1, 0]} >
    <LoadingDiv progress={progress}>
    </LoadingDiv>
  </Html>
})

const pageMesh = (pageType) => {


}
function Model(props) {
  const { nodes, materials } = useGLTF("model.glb");
  const ref = useRef()
  const extras = { receiveShadow: true, castShadow: true, "material-envMapIntensity": .4 };
  useFrame((state) => {
    ref.current.material.color.lerp(color.set(props.currentBg), 0.5);
    const et = state.clock.elapsedTime;
    ref.current.position.y = Math.sin((et * 1) / 2) * 0.5;


  })

  let mesh;
  switch (props.pageType) {
    case "work":
      mesh = <mesh ref={ref}  name="WorkSelf" position={[0, 0, 0]} geometry={nodes.WorkSelf.geometry} material={materials.M_Work} {...extras} />
      break;
    case "hobby":
      mesh = <mesh ref={ref}  name="VRSelf" position={[0, 0, 0]} geometry={nodes.VRSelf.geometry} material={materials.M_VR} {...extras} />
      break;
    case "contact":
      mesh = <mesh ref={ref}  name="MobileSelf" position={[0, 0, 0]} geometry={nodes.MobileSelf.geometry} material={materials.M_Mobile} {...extras} />
      break;
    default:
      mesh = <mesh ref={ref}  name="ThePerson" position={[0, 0, 0]} geometry={nodes.ThePerson.geometry} material={materials.M_Person} {...extras} />
  }
  return (
    mesh
  )
}


useGLTF.preload("model.glb");
