import React, { Component, useEffect } from 'react';
import './styles/App.css';
import './styles/CSSTransitions.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {useLocation} from "react-router";
import Home from "./containers/Home";
import ProjectPage from "./containers/ProjectPage";
import StandardPage from "./containers/StandardPage";
import useFetchInSanity from "./helpers/useFetchInSanity";
import ThreeDCanvas from "./components/ThreeD/ThreeDCanvas";

export default function App() {
    const siteConfig = useFetchInSanity('*[_type == "siteConfig"][0]');
    
    return (
      <Router>
          <Switch>
              <Route exact path={["/"]} component={ThreeDCanvas} />
              <Route exact path={["/no3d"]} component={Home} />
              <Route path={["/project/:projectUrlName"]} component={ProjectPage} />
              <Route path={"/about"}>

                  <StandardPage siteConfig={siteConfig} gradColors={[ "#4466cd", "#262683"]} pageType={"about"}/>
              </Route>
              <Route path={"/work"}>
                  <StandardPage siteConfig={siteConfig} gradColors={[ "#FF9555", "#D65100"]} pageType={"work"}/>
              </Route>
              <Route path={"/clients"}>
                  <StandardPage siteConfig={siteConfig} gradColors={[ "#c13cae", "#822676"]} pageType={"clients"}/>
              </Route>
              <Route path={"/hobby"}>
                  <StandardPage siteConfig={siteConfig} gradColors={[ "#FFBE4F", "#FF9F00"]} pageType={"hobby"}/>
              </Route>
              <Route path={"/contact"}>
                  <StandardPage siteConfig={siteConfig} gradColors={[ "#68519E", "#23007B"]} pageType={"contact"}/>
              </Route>

          </Switch>
      </Router>

    );

}

//export default App;
