import React, { Component } from 'react';
import styled from "styled-components";
import constants from "../globals/constants";
import piresLogo from "../assets/img/pires-logo.svg";
import {CSSTransition} from 'react-transition-group'
import client from '../client'
import imageUrlBuilder from "@sanity/image-url";
import {Link} from "react-router-dom";


const GridElementSection = styled.section`
display: flex;
flex-wrap: wrap;
`;

const   GridElementDiv = styled.div`
background-image: linear-gradient(180deg,  ${props => props.bgColor1} 0%, ${props => props.bgColor2} 100%);
background-size:1px 2000px; 
position: relative;
flex:1;
color: white;
min-width: 50vw;
transition: .2s ease-in-out;

&:hover, &:focus {
background-size:1px 70vh; 

}

min-height:50vh;
position: relative;
@media ${constants.device.tablet} {
min-height: 70vh;

}

  @media ${constants.device.tablet}  {
    min-width:100%;
  }
    .inner {
    position: absolute;
    display:flex;
    flex-direction: column;
    ${props => props.firstElement ?  "" : "border: 4px #fff solid;"  }
    margin: 2rem;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 2rem;
    overflow: hidden;
    color: white;
    transition: ease-in-out .15s;
    &:focus {
    outline-color: black;
        border: 10px #fff solid;
    }
        ${props => props.firstElement ? "" :
  "&:hover, &:focus {" +
  "transform: scale(0.975);" +
  " .divider{ transform: scaleX(1.07);}" +
  ".content {" +
  "animation: hover 1.5s ease-in-out infinite;}}" }
                          
            .inner-text{
            margin-bottom: 1rem;
            } 
        .title {
        margin: 0;
        width:100%;
        text-align: ${props => props.titlePosition};
        }  
         .divider {
         width: 50%
         min-height: 4px;
         background-color: white;
         ${props => props.titlePosition === "left" ? `margin-right: auto;` : `` };
         ${props => props.titlePosition === "center" ? `margin: auto;` : `` };
         ${props => props.titlePosition === "right" ? `margin-left: auto;` : `` };

         transition: ease-in-out .15s;
         }
         .content {
         margin: auto; 
         transition: ease-in-out .15s;
         max-height: 17.875rem;
         max-width: 17.875rem;
          img {
          max-width: 100%;
            display: block;
          }
        
         }
    
`;

const PiresLogo = styled.img`
    margin-right: 1rem;
    position: absolute;
    left: 1rem;
    top: 1rem;
    width: 3rem;
    animation: .2s ease-in-out diminish-logo forwards; 
    z-index: 99;
`;

const builder = imageUrlBuilder(client);
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      siteConfig: []
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData(){
    this.setState({
      isLoading: true,
      siteConfig: []
    });

    const query = '*[_type == "siteConfig"][0]';

    client.fetch(query).then(siteConfig => {
      this.setState({
        isLoading: false,
        siteConfig
      })
    })
  }

  render() {
    const {siteConfig, isLoading} = this.state;
    const profileImgUrl = siteConfig.profileImage ?      builder
      .image(siteConfig.profileImage.asset)
      .width(286)
      .height(286)
      .url() : "";
    const profImgUrl = siteConfig.profImage ?      builder
      .image(siteConfig.profImage.asset)
      .width(286)
      .height(286)
      .url() : "";

    const clientImgUrl = siteConfig.clientImage ?      builder
      .image(siteConfig.clientImage.asset)
      .width(286)
      .height(286)
      .url() : "";
    const hobbyImgUrl = siteConfig.hobbyImage ?      builder
      .image(siteConfig.hobbyImage.asset)
      .width(286)
      .height(286)
      .url() : "";
    const contactImgUrl = siteConfig.contactImage ?      builder
      .image(siteConfig.contactImage.asset)
      .width(286)
      .height(286)
      .url() : "";

    const GridElement = ({isReversed, title, gradColors, titlePosition, roundImg, imgUrl, text, link, animTimeOut, first}) =>
      <GridElementDiv bgColor1={gradColors[0]} bgColor2={gradColors[1]} titlePosition={titlePosition} dividerPosition={titlePosition} firstElement={first}>
        <CSSTransition appear={true} in={true} timeout={animTimeOut} classNames="slide-up">

          <Link className={"inner"} to={link}>
            {isReversed ? <>
              <div className={"content"}>
                {imgUrl !== ""  ? <img className={"rounded-img"} src={imgUrl} alt={title + " image"}/> : <h4>{text}</h4>}
              </div>
              <div className={"inner-text"}>
                <h2 className={"title"}>{title}</h2>
                <div className={"divider"}/>
              </div>

            </> : <>
              <div className={"inner-text"}>
                <h2 className={"title"}>{title.toString() + ""} </h2>
                <div className={"divider"}/>
              </div>

              <div className={"content"}>
                {imgUrl !== "" ? <img className={roundImg ? "rounded-img" : ""} src={imgUrl} alt={title + " image"}/> : <h4>{text}</h4>}
              </div>
            </> }
          </Link>
        </CSSTransition>
      </GridElementDiv>;

    const NoLinkGridElement = ({isReversed, title, gradColors, titlePosition, roundImg, imgUrl, text, link, animTimeOut, first}) =>
      <GridElementDiv bgColor1={gradColors[0]} bgColor2={gradColors[0]} titlePosition={titlePosition} dividerPosition={titlePosition} firstElement={first}>
        <CSSTransition appear={true} in={true} timeout={animTimeOut} classNames="slide-up">
          <>
            <div className={"inner"}>
              {isReversed ? <>
                <div className={"content"}>
                  {imgUrl !== ""  ? <img className={"rounded-img"} src={imgUrl} alt={title + " image"}/> : <h4>{text}</h4>}
                </div>
                <div className={"inner-text"}>
                  <h1 className={"title"}>{title}</h1>
                  <div className={"divider"}/>
                </div>

              </> : <>
                <div className={"inner-text"}>
                  <h1 className={"title"}>{title.toString() + ""} </h1>
                  <div className={"divider"}/>
                </div>

                <div className={"content"}>
                  {imgUrl !== "" ? <img className={roundImg ? "rounded-img" : ""} src={imgUrl} alt={title + " image"}/> : <h4>{text}</h4>}
                </div>
              </> }
            </div>
          </>
        </CSSTransition>
      </GridElementDiv>;

    /*
            const projectCards = projects.map(project =>
                <ProjectCard key={project._id} projectId={project._id} loading={this.state.isLoading} projectTitle={project.title}
                             img={project.thumbImage ? project.thumbImage.asset : undefined } projectSlug={project.slug.current} history= {this.props.history}/>
            );*/

    return (
      <GridElementSection>
        <>
          <PiresLogo src={piresLogo} alt={"Logo"}/>

          {/*<NoLinkGridElement title={"Edvard Pires Bjørgen"} gradColors={[ "#49D08A", "#49D08A"]} titlePosition={"left"} imgUrl={""} text={siteConfig.slogan} animTimeOut={0} first={true}/>
*/}                {<GridElement title={"About"} roundImg={true} gradColors={[ "#4466cd", "#262683"]} titlePosition={"center"} link={"/about"}
                                 imgUrl={profileImgUrl} animTimeOut={100}/>}
          <GridElement title={"Professional Work"} gradColors={[ "#FF9555", "#D65100"]} titlePosition={"right"} link={"/work"}
                       imgUrl={profImgUrl} animTimeOut={200}/>
          {/*<GridElement isReversed={true} title={"Clients"} gradColors={[ "#c13cae", "#822676"]} titlePosition={"left"} link={"/clients"}
                             imgUrl={clientImgUrl} animTimeOut={300}/>*/}
          <GridElement isReversed={true} title={"Hobby Work"} gradColors={[ "#FFBE4F", "#FF9F00"]} titlePosition={"center"} link={"/hobby"}
                       imgUrl={hobbyImgUrl} animTimeOut={400}/>
          <GridElement isReversed={true} title={"Contact"} gradColors={[ "#68519E", "#23007B"]} titlePosition={"right"} link={"/contact"}
                       imgUrl={contactImgUrl} animTimeOut={500}/>
        </>
      </GridElementSection>

    );
  }
}

export default Home;
