import React, {useEffect} from "react"
import styled from "styled-components";
import { init } from 'ityped'
import constants from "../../globals/constants";

const StyledElement = styled.div`
  background-color: ${props => props.bgColor};
  transition: background-color .2s ease-in-out;
  height: ${props => props.divHeight};
  border: 8px solid white;
  box-sizing: border-box;

  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align:center;
  
  div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

  }
  .title {
    margin: 0 auto;
    color: white;
    font-size: 7rem;
    min-height: 10rem;
    line-height:10rem;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 300;
    
    @media ${constants.device.tablet} {
      font-size: 5rem;
    }
    @media ${constants.device.mobileXL} {
      font-size: 3.5rem;
      min-height: 1rem;
      line-height: 3.5rem;
    }
  }
  .subtitle {
    font-size: 2.5rem;
    @media ${constants.device.mobileXL} {
      font-size: 2rem;
    }
  }



`;

const FullScreenOverlayElement = ((props) => {
  useEffect(() => {
    const myElement = document.querySelector('#title')
    init(myElement, { showCursor: false, strings: props.titles, backDelay:  1000 })
  },[])
  return (

    <StyledElement id={props.anchor} bgColor={props.bgColor} divHeight={props.height}>
      <div>
        <span className={"title"}>{"I am a "}</span>
        <h1 className={"title"} id={"title"}/>
      </div>
      <span className={"subtitle"}>{props.subtitle}</span>
    </StyledElement>)})

export default FullScreenOverlayElement
