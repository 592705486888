import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import imageUrlBuilder from '@sanity/image-url'
import client from '../client'
import constants from '../globals/constants'
import piresLogo from '../assets/img/pires-logo.svg'
import { Link, useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import ProjectCard from '../components/ProjectCard'
import ContactInfo from '../components/ContactInfo'
import WorkClients from '../components/WorkClients'
import OrbitControlModel from '../components/ThreeD/OrbitControlModel'
import useFetchInSanity from '../helpers/useFetchInSanity'

const BlockContent = require('@sanity/block-content-to-react')
const PageBody = styled.div`
  min-height: 100vh;
  background-image: linear-gradient(
    180deg,
    ${(props) => props.gradColor1} 0%,
    ${(props) => props.gradColor2} 100%
  );
  padding: 8rem;

  @media ${constants.device.tablet} {
    padding: 6rem 1rem 1rem 1rem;
  }

  .BottomFooterText {
    position: relative;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    color: white;
    margin-top: 2rem;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    .BottomText {
      font-size: 1rem;
    }
  }
`

const PiresLogo = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  transition: ease-in-out 0.2s;
  z-index: 10;
  &:hover,
  &:focus {
    img {
      margin-right: 2rem;
    }
  }
  img {
    margin-right: 1rem;
    transition: ease-in-out 0.2s;
    animation: 0.2s ease-in-out expand-logo forwards;
  }
  a {
    width: 100%;
    height: 100%;
    display: flex;
  }
  p {
    margin: auto 0;
    animation: 0.5s ease-in-out fade-in forwards;
  }
`

const PageContent = styled.article`
  border: solid 12px white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;

  @media ${constants.device.tablet} {
    border: solid 4px white;
  }
`
const PageContentArticleHeader = styled.section`
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 11rem;
    margin: 0;
    color: white;
    text-align: center;
    @media ${constants.device.containerWidth} {
      font-size: 6.6rem;
    }
    @media ${constants.device.tablet} {
      font-size: 4rem;
    }
  }
  .topTitle {
    position: relative;
    top: 9.5rem;
    z-index: 1;
    @media ${constants.device.tablet} {
      top: 6.5rem;
    }
  }
  .bottomTitle {
    position: relative;
    top: -9.5rem;
    @media ${constants.device.tablet} {
      top: -6.5rem;
    }
  }
  img {
    animation: hover-longer ease-in-out 3s infinite;
    ${(props) => (props.isAboutPage ? 'border-radius: 50%;' : '')}
    margin: 4rem auto;
    max-width: 100%;
    min-height: 32rem;
    @media ${constants.device.tablet} {
      width: 15rem;
      min-height: 15rem;
    }
  }
  .textBody {
    margin: 0 3rem;
    position: relative;
    top: -9.5rem;
    @media ${constants.device.tablet} {
      margin: 0 1rem;
      top: -6.5rem;
    }

    p {
      margin: 1rem 0;
    }
  }
`

const PageContentArticleFooter = styled.footer`
    border-top: 12px solid white;
    border-top: 4px solid white;

    }
    .footerContent {
        margin: 1rem 3rem;
        display: flex;
        @media ${constants.device.tablet} {
            justify-content: center;
            flex-direction: column;
            p {
                text-align: center;}
        }
    }
    .buttons {
        margin: auto 0 auto auto;
        display: flex;
        @media ${constants.device.tablet} {
            flex-direction: column;
            margin: 0 auto;
        }
        a {
            margin: auto 0;
            padding: 0 2rem;
            cursor: pointer;
            border: 4px solid white;
            text-align: center;
            margin: 0 .25rem;
            transition: opacity .2s ease-in-out, color .3s ease-in-out, left .2s ease-in-out;
            position: relative;
            &:hover, &:focus{
                color: ${(props) => props.linkColor};
                &:before {
                    height:100%;
                }
            }
            &:before {
                z-index: -1;
                content: '';
                height:0;
                width: 100%;
                background-color: white;
                position: absolute;
                left: 0;
                bottom: 0;
                transition: .2s ease-in-out;
            }
            &:first-child {
                margin: 0 1rem 0 0;
                @media ${constants.device.tablet} {
                    margin: 0 0 1rem 0;

                }
            }
        }
    }
`

const builder = imageUrlBuilder(client)
export default function StandardPage(props) {
  const [pageData, setPageData] = useState([])
  const [pageImg, setPageImg] = useState([])
  const [componentData, setComponentData] = useState([])
  const [components, setComponents] = useState([])
  const [siteConfig, setSiteConfig] = useState([])
  const { gradColors, pageType } = props
  const { pathname } = useLocation()

  useEffect(() => {
    loadSiteConfig()
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    document.body.style.backgroundColor = gradColors[0];
    loadPageData(pageType)

    //setPageImg();

    switch (pageType) {
      case 'about':
        break
      case 'work':
        loadProjectData(pageType)
        break
      case 'hobby':
        loadProjectData(pageType)
        break
      case 'clients':
        setComponents(<WorkClients />)
        break
      case 'contact':
        console.log(siteConfig)
        setComponents(
          <ContactInfo siteConfig={siteConfig} gradColor2={gradColors[1]} />,
        )
        break
    }
  }, [siteConfig])

  useEffect(() => {

    const img = siteConfig.profileImage ? builder.image(pageData.pageImage).width(512).height(512).url() : ""
    setPageImg(img);
    
  }, [pageData])
  const loadPageData = async (pageKeyVar) => {
    const params = { pageKey: pageKeyVar }
    const response = await client.fetch(
      '*[_type == "standardPage" && pageKey == $pageKey][0]',
      params,
    )
    console.log("pagedata", response);
    
    setPageData(response)
  }

  const loadSiteConfig = async () => {
    const response = await client.fetch('*[_type == "siteConfig"][0]')
    console.log("sitecomfig",response)
    
    setSiteConfig(response)
  }

  const loadProjectData = async (projectKeyVar) => {
    const params = { projectKeyVar: projectKeyVar }
    const response = await client.fetch(
      '*[_type == "projectCase" && projectType == $projectKeyVar]',
      params,
    )
    const projectCards = response.map((project) => (
      <ProjectCard
        key={project._id}
        projectData={project}
        gradColor2={gradColors[1]}
      />
    ))
    setComponents(projectCards)
    setComponentData(response)
  }

  return (
    <PageBody gradColor1={gradColors[0]} gradColor2={gradColors[1]}>
      <PiresLogo>
        <Link to={'/'}>
          <img src={piresLogo} alt={'Home Link'} />
          <p>Home</p>
        </Link>
      </PiresLogo>
      <CSSTransition
        appear={true}
        in={true}
        timeout={100}
        classNames="slide-up"
      >
        <PageContent>
          <PageContentArticleHeader
            className={'PageContentArticleHeader'}
            isAboutPage={pageType === 'about'}
          >
            <h1 className={'topTitle'}>{pageData.pageTitle1}</h1>
            {pageData.activate3dModel ? (
              <OrbitControlModel
                pageType={pageType}
                currentBg={gradColors[0]}
              />
            ) : (
              <img src={pageImg ? pageImg : ""} alt={pageData.pageTitle} />
            )}
            <h1 className={'bottomTitle'}>{pageData.pageTitle2}</h1>
            <div className={'textBody'}>
              <BlockContent blocks={pageData.bodyText} />
            </div>
            <div className={'divider'} />
          </PageContentArticleHeader>
          {components}
          <PageContentArticleFooter linkColor={gradColors[1]}>
            <div className={'footerContent'}>
              <p>Wanna learn more about me?</p>
              <div className={'buttons'}>
                <a
                  href={'https://' + siteConfig.linkedInUrl + ''}
                  target={'blank'}
                >
                  LinkedIn
                </a>
                <a
                  href={'https://' + siteConfig.githubUrl + ''}
                  target={'blank'}
                >
                  GitHub
                </a>
              </div>
            </div>
          </PageContentArticleFooter>
        </PageContent>
      </CSSTransition>
      <div className={'BottomFooterText'}>
        <p className={'BottomText'}>
          2016-{new Date().getFullYear()} Edvard Pires Bjørgen
        </p>
        <p className={'BottomText'}>
          Fully made with React + React Three Fiber + Sanity.io ❤️{' '}
        </p>
      </div>
    </PageBody>
  )
}
