


const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    mobileXL: '525px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1200px',
    desktop: '2560px',
    customHeight: '800px',
    containerWidth: '1200px'
};
let constants = {
    device : {
        mobileS: `(max-width: ${size.mobileS})`,
        mobileM: `(max-width: ${size.mobileM})`,
        mobileL: `(max-width: ${size.mobileL})`,
        mobileXL: `(max-width: ${size.mobileXL})`,
        tablet: `(max-width: ${size.tablet})`,
        laptop: `(max-width: ${size.laptop})`,
        laptopL: `(max-width: ${size.laptopL})`,
        desktop: `(max-width: ${size.desktop})`,
        desktopL: `(max-width: ${size.desktop})`,
        customHeight: `(max-height: ${size.customHeight})`,
        containerWidth: `(max-width: ${size.containerWidth})`
    }
};
const variable = {
    footerHeight : '5rem'
};

export default constants;
