/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import React, { useEffect, useRef, useState } from "react"
import { useGLTF, useAnimations, PerspectiveCamera } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"

const color = new THREE.Color();

export default function Model({ scroll, currentIndex, currentBg, ...props }) {
  const t = useRef(0);
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("model.glb");
  const { actions, mixer } = useAnimations(animations, group);
  const [hovered, setHovered] = useState();
  const [currentMesh, setCurrentMesh] = useState({});
  const extras = { receiveShadow: true, castShadow: true, "material-envMapIntensity": .4 };
  useEffect(() => void actions["CameraAction.005"].play(), []);
  useEffect(() => {
    let i = currentIndex;
    if (i > 0){
      setCurrentMesh(group.current.children[0].children[i-1]);
    }

  }, [currentIndex]);

  /*useEffect(() => {
    if (hovered) group.current.getObjectByName(hovered).material.color.set("white")
    document.body.style.cursor = hovered ? "pointer" : "auto"
  }, [hovered])*/

  useFrame((state) => {
    if (actions["CameraAction.005"]._clip){
      mixer.setTime((t.current = THREE.MathUtils.lerp(t.current, actions["CameraAction.005"]._clip.duration * scroll.current, 0.05)));}
    group.current.children[0].children.forEach((child, index) => {
      child.material.color.lerp(color.set(currentMesh.name === child.name ? currentBg : "#202020"), currentMesh.name === child.name ? 0.9 : 0.05);
      //child.material.color.lerp(color.set(hovered === child.name ? "#ffffff" : "#202020").convertSRGBToLinear(), hovered ? 0.9 : 0.05)
      const et = state.clock.elapsedTime;
      child.position.y = Math.sin((et + index * 1000) / 2) * 0.4;
      child.rotation.x = Math.sin((et + index * 1000) / 3) / 80;
      //child.rotation.y = Math.cos((et + index * 1000) / 2) / 20;
    })
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group
        //onPointerOver={(e) => (e.stopPropagation(), setHovered(e.object.name))}
        //onPointerOut={(e) => (e.stopPropagation(), setHovered(null))}
        position={[0.06, 4.04, 0.35]}
      >
        <mesh name="ThePerson" geometry={nodes.ThePerson.geometry} material={materials.M_Person} {...extras} />
        <mesh name="Work" geometry={nodes.Work.geometry} material={materials.M_Work} {...extras} />
        <mesh name="VR" geometry={nodes.VR.geometry} material={materials.M_VR} {...extras} />
        <mesh name="Mobile" geometry={nodes.Mobile.geometry} material={materials.M_Mobile} {...extras} />
      </group>
      <group name="Camera" position={[-1.78, 2.04, 23.58]} rotation={[1.62, 0.01, 0.11]}>
        <PerspectiveCamera makeDefault far={100} near={0.1} fov={28} rotation={[-Math.PI / 2, 0, 0]}>
          <directionalLight
            castShadow
            position={[10, 20, 15]}
            shadow-camera-right={8}
            shadow-camera-top={8}
            shadow-camera-left={-8}
            shadow-camera-bottom={-8}
            shadow-mapSize-width={1024}
            shadow-mapSize-height={1024}
            intensity={2}
            shadow-bias={-0.0001}
          />
        </PerspectiveCamera>
      </group>
    </group>
  )
}

useGLTF.preload("model.glb");
