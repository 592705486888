import React, { forwardRef, useEffect, useState } from "react"
import TransparentOverlayElement from "./TransparentOverlayElement"
import styled from "styled-components";
import piresLogo from "../../assets/img/pires-logo.svg";
import FullScreenOverlayElement from "./FullscreenOverlayElement";
import useKeypress from "../../helpers/useKeypress";
import {CSSTransition} from "react-transition-group";

const PiresLogo = styled.img`
  margin-right: 1rem;
  position: fixed;
  left: 1rem;
  top: 1rem;
  width: 3rem;
  animation: .2s ease-in-out diminish-logo forwards;
  z-index: 99;
`;

const NavBar = styled.div`
  position: fixed;
  bottom: 2rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

const NavBtn = styled.a`
  height: 3rem;
  width: 3rem;
  line-height: 3.2rem;
  border: 4px solid white;
  text-align: center;
  font-size: 2rem;
  margin: 0 .25rem;
  transition: opacity .2s ease-in-out, color .3s ease-in-out, left .2s ease-in-out;
  position: relative;
  transform: ${props => props.rotate180deg ? "rotate(180deg)" : "rotate(0)"};
  &:hover, &:focus{
    color: ${props => props.fontColor};
    &:before {
      height:100%;
    }
  }
  &:before {
    z-index: -1;
    content: '';
    height:0;
    width: 100%;
    background-color: white;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: .2s ease-in-out;
  }

`;


const Overlay = forwardRef(({ caption, scroll, handleIndexChange, handleBgChange }, ref) => {
  const [bgGradient, setBgGradient] = useState("#49d08a");
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [showUpButton, setShowUpButton] = useState(false);

  useEffect( () => {
    document.body.style.backgroundColor = bgGradient;
    handleBgChange(bgGradient)
  },[bgGradient]);

  useEffect(() => {
    handleIndexChange(currentSectionIndex)
    if(currentSectionIndex === 0) {
      setShowUpButton(true)
    } else {setShowUpButton(false)}
  }, [currentSectionIndex]);

  const determineSection = (direction) => {
    const newIndex = currentSectionIndex + direction;
    if (newIndex > anchorNames.length -1) {
      setCurrentSectionIndex(0)
    } else if (newIndex < 0) {
      setCurrentSectionIndex(0)
    }
    else
    {    setCurrentSectionIndex(newIndex) }
  };

  const anchorNames = [
    "intro",
    "about",
    "professional",
    "hobby",
    "contact"
  ];
  //TODO: switch out from sanity
  const fullscreenTitle = [
    "UX Designer",
    "VR Developer",
    "Web Developer"
  ];

  const green = "#49d08a";
  const blue = "#4466cd";
  const orange =  "#ff9555";
  const magenta = "#c13cae";
  const yellow =  "#ffbe4f";
  const purple = "#68519e";
  return (
    <div
      ref={ref}
      onScroll={(e) => {
        scroll.current = e.target.scrollTop / (e.target.scrollHeight - window.innerHeight);
       //caption.current.innerText = scroll.current.toFixed(2);
        let currentScrollPercentage = parseFloat(scroll.current.toFixed(2));

        switch (currentScrollPercentage) {
          //Green
          case 0.00:
            setBgGradient(green);
            setCurrentSectionIndex(0);
            break;
          /// Blue
          case 0.10:
            setBgGradient(blue);
            setCurrentSectionIndex(1);
            break;
          case 0.18:
            setBgGradient(blue);
            setCurrentSectionIndex(1);
            break;
          //Orange
          case 0.43:
            setBgGradient(orange);
            setCurrentSectionIndex(2);
            break;
          case 0.49:
            setBgGradient(orange);
            setCurrentSectionIndex(2);
            break;
          //Yellow
          case 0.72:
            setBgGradient(yellow);
            setCurrentSectionIndex(3);
            break;
          case 0.84:
            setBgGradient(yellow);
            setCurrentSectionIndex(3);
            break;
          case 0.97:
            setBgGradient(purple);
            setCurrentSectionIndex(4);
            break
        }


      }}
      className="scroll">
      <PiresLogo src={piresLogo} alt={"Logo"}/>
      <NavBar>
        <CSSTransition in={!showUpButton} timeout={200} classNames="button" unmountOnExit>
       <NavBtn fontColor={bgGradient} moveAtTop = {true} hiddenProp = {true} href={"#" + anchorNames[currentSectionIndex]} tab-index onClick= {() => {determineSection(-1)}} >⌃</NavBtn>
        </CSSTransition>
        <CSSTransition in={showUpButton} timeout={200} classNames="buttonSlide" >
        <NavBtn fontColor={bgGradient} moveAtTop = {currentSectionIndex !== 0} hiddenProp = {true} rotate180deg={true}  href={"#" + anchorNames[currentSectionIndex]} onClick= {() => {determineSection(1)}} >⌃</NavBtn>
        </CSSTransition>
      </NavBar>

      <FullScreenOverlayElement anchor={"intro"} height={"100vh"} titles={fullscreenTitle}
                                bgColor={bgGradient}
                                subtitle={"Edvard Pires Bjørgen"}/>

      <TransparentOverlayElement anchor={"about"} height={"300vh"} title={"About me"}
                                 text={""} bgColor={bgGradient}
                                 url={"about"} urlText={"Read more about me"} />

      <TransparentOverlayElement anchor={"professional"} height={"275vh"} title={"Professional projects"}
                                 text={""} bgColor={bgGradient}
                                 url={"work"} urlText={"See more of my work"} />
      <TransparentOverlayElement anchor={"hobby"}height={"250vh"} title={"Hobby projects"}
                                 text={""} bgColor={bgGradient}
                                 url={"hobby"} urlText={"Take a look at my projects"} />
      <TransparentOverlayElement  anchor={"contact"} height={"95vh"} title={"Contact"}
                                  text={""} bgColor={bgGradient}
                                  url={"contact"} urlText={"Contact me"} />


      {/*<a href={"#first"} className="caption" ref={caption}>
        0.00
      </a>*/}
    </div>
  )});

export default Overlay
