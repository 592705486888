import React, { useState, useEffect } from "react";
import styled from "styled-components";
import client from "../client";
import imageUrlBuilder from "@sanity/image-url";
import constants from "../globals/constants";
const WorkBody = styled.div`
display: flex;
margin: 0 auto 4rem auto;
    @media ${constants.device.containerWidth} { 
        flex-direction: column;
    }
    .workElement {
    
        border: 4px solid white;
        padding: 1.5rem;
        display: flex;
        margin-right: auto;
        flex-direction: column;
        width: 12rem;
        margin: 0 1rem;
        min-height: 15.5rem;

        &:first-child {
        margin: 0 1rem 0 0;
        }
        &:last-child {
        margin: 0 0 0 1rem;
        }
            @media ${constants.device.containerWidth} { 
        margin: 1rem 0;

        &:first-child {
         margin: 0 0 1rem 0;

        }
        &:last-child {
        margin: 1rem 0 0 0;
        }
    }
        
        img {
            margin: auto;
            
        }
        .divider {
            height: 3px;
            width:100%;
            background-color: white;
            margin: 0 auto 1rem 0;
        }
                a { 
                position: relative;
                transition: .2s ease-in-out;
                margin: 0 auto 0 0;

                    &:hover, &:focus{
                           color: ${props => props.gradColor2};
                           &:before {
                                height:100%;
                                
                           }
                        }
                
                &:before {
                    z-index: -1;
                    content: '';
                    height:2px;
                    width: 100%;
                    background-color: white;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transition: .2s ease-in-out;
                    
                   
                     }
                }
                }

`;

const builder = imageUrlBuilder(client);

export default function WorkClients(props)  {
    const [workClients, setWorkClients] = useState([]);
    useEffect(() => {
        const query = '*[_type == "workClients"]';
        loadWorkClients(query);
    }, []);

    const img = (url) => url ? builder
        .image(url)
        .auto("format")
        .url() : "";

    const loadWorkClients = async (query) => {
        const response = await client.fetch(query);
        setWorkClients(response);
    };

    return (
        <WorkBody gradColor2={props.gradColor2}>
            {console.log(workClients)}
            {
                workClients.map(workClient =>
                    <div key={workClient._id} className={"workElement"}>
                        <img src={img(workClient.clientLogo.asset)} alt={"mail"}/>
                    </div>
                )
            }


        </WorkBody>
    )
}
