// useFetch.js
import { useState, useEffect } from "react";
import client from "../client";

export default function useFetchInSanity(query) {
    const [data, setData] = useState([]);

    async function getData() {
        const response = await client.fetch(query);
        //const data = await response.json();
        setData(response);
    }

    useEffect(() => {
        getData();
    }, []);

    return data;
}
