import React, { useState, useEffect } from "react";
import styled from "styled-components";
import mailIcon from "../assets/img/icons/mail.svg";
import githubIcon from "../assets/img/icons/github.svg";
import linkedinIcon from "../assets/img/icons/linkedin.svg";
import constants from "../globals/constants";
const ContactBody = styled.div`
display: flex;
margin: 0 auto 4rem auto;
    @media ${constants.device.containerWidth} { 
        flex-direction: column;
    }
    .contactElement {
    
        border: 4px solid white;
        padding: 1.5rem;
        display: flex;
        margin-right: auto;
        flex-direction: column;
        width: 12rem;
        height: 8rem;
        margin: 0 1rem;

        
        &:first-child {
        margin: 0 1rem 0 0;
        }
        &:last-child {
        margin: 0 0 0 1rem;
        }
        
             @media ${constants.device.containerWidth} { 
        margin: 1rem 0;

        &:first-child {
         margin: 0 0 1rem 0;

        }
        &:last-child {
        margin: 1rem 0 0 0;
        }
    }
        
        img {
            margin: 0 auto .25rem 0;
            
        }
        .divider {
            height: 3px;
            width:100%;
            background-color: white;
            margin: 0 auto 1rem 0;
        }
                a { 
                position: relative;
                transition: .2s ease-in-out;
                margin: 0 auto 0 0;

                    &:hover, &:focus{
                           color: ${props => props.gradColor2};
                           &:before {
                                height:100%;
                                
                           }
                        }
                
                &:before {
                    z-index: -1;
                    content: '';
                    height:2px;
                    width: 100%;
                    background-color: white;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transition: .2s ease-in-out;
                    
                   
                     }
                }
                }

`;
export default function ContactInfo(props)  {
    return (
        <ContactBody gradColor2={props.gradColor2}>
            <div className={"contactElement"}>
                <img src={mailIcon} alt={"mail"}/>
                <div className={"divider"}/>
                <a href={"mailto:"+ props.siteConfig.email} target={"blank"}>{props.siteConfig.email}</a>
            </div>
            <div className={"contactElement"}>
                <img src={githubIcon} alt={"github"}/>
                <div className={"divider"}/>
                <a href={"https://" + props.siteConfig.githubUrl} target={"blank"}> {props.siteConfig.githubUrl}</a>
            </div>
            <div className={"contactElement"}>
                <img src={linkedinIcon} alt={"linked in"}/>
                <div className={"divider"}/>
                <a  href={"https://" + props.siteConfig.linkedInUrl} target={"blank"}>{props.siteConfig.linkedInUrl}</a>
            </div>

        </ContactBody>
    )
}
